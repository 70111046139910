import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "styled-components";
import Globalize from "../../../common-deprecated/Globalize";
import getTheme from "../../../common-deprecated/themes/getTheme";
import { modelFilterClientStore, ModelFilterStateType } from "../model-filter/redux/store";
import { renderReact } from "../../../common-deprecated/utils/clientRenderUtils";
import { ModalProvider } from "../../../common-deprecated/containers/ModalProvider";
import { WindowDimensionsProvider } from "../../../common-deprecated/containers/WindowDimensionsProvider";
import { removeLastSlashAndWhiteSpace } from "../../../common-deprecated/utils";

export const getInit =
    (ModelFilterResults: React.ElementType) =>
    (target: string, props: unknown, initialState: ModelFilterStateType): void => {
        const { commonSettings } = initialState;
        const { country, culture, currencyMultiplier } = commonSettings;

        // eslint-disable-next-line camelcase
        __webpack_public_path__ = `${removeLastSlashAndWhiteSpace(commonSettings.resourcePath)}/`;

        // Initialize store, tagging and globalize.
        // Same store as Model-Filter, we could split them to optimize bundle size but currently not worth the effort.
        const store = modelFilterClientStore(initialState, commonSettings.component);
        Globalize(country, culture, currencyMultiplier);

        renderReact(
            target,
            commonSettings,
            <ReduxProvider store={store}>
                <ThemeProvider theme={getTheme(commonSettings)}>
                    <WindowDimensionsProvider>
                        <ModalProvider>
                            <ModelFilterResults />
                        </ModalProvider>
                    </WindowDimensionsProvider>
                </ThemeProvider>
            </ReduxProvider>,
        );
    };
