import React from "react";
import * as Preloader from "../../../../../common-deprecated/styles/v2/toyota/custom/Preloader";
import * as Styles from "./styles/ModelFilterResultsToyotaStyles";
import { useModelFilterSelector } from "../../../model-filter/redux/store";
import ModelResult from "../../../model-filter/components/toyota/model-results/ModelResult";
import useModelFilterResults from "../../hooks/useModelFilterResults";
import { useModalMap } from "../../../../../common-deprecated/containers/ModalProvider";
import { aemToyotaCommonModalMap } from "../../../../../common-deprecated/components/modals/aem/modals";

const ModelFilterResults = (): JSX.Element => {
    const { initialized, results } = useModelFilterSelector((state) => state.modelResults);

    useModelFilterResults();
    useModalMap(aemToyotaCommonModalMap);

    return (
        <Styles.Wrapper>
            {initialized ? (
                <Styles.Grid>
                    {results.map((model) => (
                        <ModelResult key={model.id} model={model} />
                    ))}
                </Styles.Grid>
            ) : (
                <Preloader.Wrapper width={1} position="relative">
                    <Preloader.default />
                </Preloader.Wrapper>
            )}
        </Styles.Wrapper>
    );
};

export default ModelFilterResults;
